<template>
  <div class="fixed inset-0 z-40 flex items-center justify-center">
    <div class="fixed inset-0 bg-black opacity-40" />
    <div
      class="z-50 flex h-[85svh] w-4/5 flex-col overflow-hidden rounded-md bg-white p-px shadow-xl ring-1 ring-inset ring-gray-300"
    >
      <section
        class="flex flex-col rounded-t-md border-b border-gray-300 bg-gray-50 font-sans"
      >
        <div class="flex">
          <h1 class="grow self-center px-2 py-1 font-semibold">
            <slot name="title" />
          </h1>

          <div class="justify-end text-sm">
            <EditorToolbar>
              <slot name="buttons" />

              <EditorSeparator v-if="hasButtons" />

              <EditorButton v-if="hasToolbar" @click="toggleToolbar">
                <i class="fa-solid fa-bars"></i>
              </EditorButton>

              <EditorButton
                class="rounded-full bg-red-50 hover:bg-red-100"
                @click="$emit('close')"
              >
                <i class="fa-solid fa-xmark"></i>
              </EditorButton>
            </EditorToolbar>
          </div>
        </div>
      </section>

      <section
        v-if="hasToolbar && showToolbar"
        class="flex flex-col gap-1 border-b border-gray-500 bg-gray-300 p-1 font-sans shadow-inner"
      >
        <slot name="toolbar" />
      </section>
      <section class="grow overflow-y-auto p-2">
        <slot name="default" />
      </section>
      <section
        v-if="hasActions"
        class="flex flex-col rounded-b-md border-t border-gray-300 bg-gray-50 px-2 font-sans"
      >
        <EditorToolbar class="justify-end gap-2 text-sm">
          <slot name="actions" />
        </EditorToolbar>
      </section>
    </div>
  </div>
</template>

<script>
import EditorButton from "@/components/CommonEditor/EditorButton.vue";
import EditorSeparator from "@/components/CommonEditor/EditorSeparator.vue";
import EditorToolbar from "@/components/CommonEditor/EditorToolbar.vue";

export default {
  components: {
    EditorButton,
    EditorSeparator,
    EditorToolbar,
  },

  data() {
    return {
      showToolbar: false,
    };
  },

  methods: {
    toggleToolbar() {
      this.showToolbar = !this.showToolbar;
    },
  },

  computed: {
    hasToolbar() {
      return this.$slots.toolbar;
    },
    hasButtons() {
      return this.$slots.buttons;
    },
    hasActions() {
      return this.$slots.actions;
    },
  },
};
</script>
