import AnswerFieldGroupWidget from "./AnswerFieldGroupWidget.vue";
export default AnswerFieldGroupWidget;

import { mergeAttributes, Node, Mark } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    answerField: {
      toggleAnswerField: () => ReturnType;
    };
  }
}
declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    answerFieldGroup: {
      toggleAnswerFieldGroup: () => ReturnType;
    };
  }
}

export const AnswerFieldExtension = Mark.create({
  name: "answerField",

  group: "inline",

  addAttributes() {
    return {};
  },

  parseHTML() {
    return [
      {
        tag: "span",
        getAttrs: (dom: any) => {
          if (dom.getAttribute("data-type") !== "answer-field") {
            return false;
          }

          return {};
        },
      },
    ];
  },

  renderHTML() {
    return [
      "span",
      mergeAttributes({
        "data-type": "answer-field",
      }),
      0,
    ];
  },

  addCommands() {
    return {
      toggleAnswerField: () => {
        return ({ commands }) => {
          return commands.toggleMark(this.name);
        };
      },
    };
  },
});

export const AnswerFieldGroupExtension = Node.create({
  name: "answerFieldGroup",

  group: "block",

  content: "block+",

  draggable: true,

  addAttributes() {
    return {};
  },

  parseHTML() {
    return [
      {
        tag: "section",
        getAttrs: (dom: any) => {
          if (dom.getAttribute("data-type") !== "answer-field-group") {
            return false;
          }

          return {};
        },
      },
    ];
  },

  renderHTML() {
    return [
      "section",
      mergeAttributes({ "data-type": "answer-field-group" }),
      0,
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(AnswerFieldGroupWidget);
  },

  addCommands() {
    return {
      toggleAnswerFieldGroup() {
        return ({ commands, state }) => {
          const selection = state.selection;
          const { from, to } = selection;

          if (from === to) {
            return commands.insertContent({
              type: "answerFieldGroup",
              attrs: {},
              content: [{ type: "paragraph", content: [] }],
            });
          }

          return commands.wrapIn("answerFieldGroup", {});
        };
      },
    };
  },
});
