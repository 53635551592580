import ClickableGroupWidget from "./ClickableGroupWidget.vue";
export default ClickableGroupWidget;

import { mergeAttributes, Node, Mark } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";

export const ClickableGroupItemExtension = Mark.create({
  name: "clickableGroupItem",

  group: "inline",

  addAttributes() {
    return {
      correct: {
        default: "false",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span",
        getAttrs: (dom: any) => {
          if (dom.getAttribute("data-type") !== "clickable-group-item") {
            return false;
          }

          return {
            correct: dom.getAttribute("data-correct") === "true",
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes({
        "data-type": "clickable-group-item",
        "data-correct": HTMLAttributes.correct,
      }),
      0,
    ];
  },

  addCommands() {
    return {
      toggleClickableGroupItem:
        (attributes) =>
        ({ commands }) => {
          return commands.toggleMark("clickableGroupItem", attributes);
        },
    };
  },
});

export const ClickableGroupExtension = Node.create({
  name: "clickableGroup",

  group: "block",

  content: "block+",

  draggable: true,

  addAttributes() {
    return {};
  },

  parseHTML() {
    return [
      {
        tag: "section",
        getAttrs: (dom: any) => {
          if (dom.getAttribute("data-type") !== "clickable-group") {
            return false;
          }

          return {};
        },
      },
    ];
  },

  renderHTML() {
    return ["section", mergeAttributes({ "data-type": "clickable-group" }), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(ClickableGroupWidget);
  },
});
