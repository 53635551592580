<script>
import ProblemEditor from "@/components/ProblemEditor";

export default {
  name: "ProblemForm",
  components: {
    ProblemEditor,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
    textarea: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      data: { ...this.content },
    };
  },
  watch: {
    data: {
      handler: function (newVal) {
        document.querySelector(this.textarea).value = JSON.stringify(
          newVal,
          null,
          2,
        );
      },
      deep: true,
    },
  },
};
</script>

<template>
  <div>
    <ProblemEditor
      :content:json="data"
      @update:json="(json) => (data = json)"
    />
  </div>
</template>
