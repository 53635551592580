import ProblemWidget from "./ProblemWidget.vue";
export default ProblemWidget;

import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";

export const ProblemExtension = Node.create({
  name: "problem",

  group: "block",

  atom: true,

  draggable: true,

  addAttributes() {
    return {
      problem: {
        default: null,
      },
      points: {
        default: 10,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "object",
        getAttrs: (dom: any) => {
          if (dom.getAttribute("data-type") !== "problem") {
            return false;
          }
          const problem = dom.getAttribute("data-problem");
          const points = Number(dom.getAttribute("data-points"));
          return { problem, points };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "object",
      mergeAttributes({
        "data-type": "problem",
        "data-problem": HTMLAttributes.problem,
        "data-points": HTMLAttributes.points,
      }),
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(ProblemWidget);
  },
});
