<script>
import { isEmpty, each } from "lodash";
export default {
  name: "ProblemFilter",
  props: {
    selectedFilters: {
      type: Object,
      default: () => ({}),
    },
  },
  data: function () {
    return {
      filters: {},
      contexts: [
        {
          context: "subjects",
          label: "Subjects",
          scope: "subject_ids",
        },
        {
          context: "grade_levels",
          label: "Grade Levels",
          scope: "grade_level_ids",
        },
        {
          context: "topics",
          label: "Topics",
          scope: "topic_ids",
        },
      ],
      tags: {
        subjects: {},
        grade_levels: {},
        topics: {},
      },
    };
  },
  computed: {
    isEmpty: function () {
      return isEmpty;
    },
    checked: function () {
      return (scope, value) => {
        return this.filters[scope] && this.filters[scope].includes(value);
      };
    },
  },
  methods: {
    fetchTags: function (context, url = null) {
      url = url || `/api/tags/${context}/`;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          if (isEmpty(this.tags[context])) {
            this.tags[context] = data;
          } else {
            this.tags[context].data = this.tags[context].data.concat(data.data);
            this.tags[context].metadata = data.metadata;
          }
        });
    },
    check(e, scope, value) {
      if (e.target.checked) {
        if (!this.filters[scope]) {
          this.filters[scope] = [];
        }
        this.filters[scope].push(value);
      } else {
        this.filters[scope] = this.filters[scope].filter((v) => v !== value);
      }
    },

    updateFilters: function () {
      this.$emit("filter", this.filters);
    },
  },
  mounted: function () {
    each(this.contexts, ({ context }) => {
      console.log("Mounted", context, !this.tags[context].data);
      if (!this.tags[context].data) {
        this.fetchTags(context);
      }
    });
  },
  watch: {
    selectedFilters: {
      handler: function (value) {
        this.filters = value;
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <div class="form flex flex-col gap-2">
    <h2 class="text-xl font-semibold">Filters</h2>

    <template v-for="context in contexts" :key="context.context">
      <div>
        <h4 class="mb-2 text-lg text-gray-600">{{ context.label }}</h4>
        <div
          v-if="tags[context.context].data"
          class="max-h-72 overflow-auto pl-2"
        >
          <div
            class="field checkbox"
            v-for="tag in tags[context.context].data"
            :key="tag.id"
          >
            <input
              type="checkbox"
              :id="tag.id"
              @change="(e) => check(e, context.scope, tag.id)"
              :checked="checked(context.scope, tag.id)"
              :value="tag.value"
            />
            <label :for="tag.id">{{ tag.name }}</label>
          </div>
          <a
            v-if="tags[context.context].metadata.next"
            href="#"
            class="text-sm text-gray-600 underline"
            @click.prevent="
              fetchTags(context.context, tags[context.context].metadata.next)
            "
          >
            Load more
          </a>
        </div>
      </div>
    </template>

    <button class="btn btn-primary mt-2 px-2 py-1" @click="updateFilters">
      Filter
    </button>
  </div>
</template>
