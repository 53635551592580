<template>
  <node-view-wrapper
    as="span"
    class="min inline-block rounded border border-gray-500 bg-gray-50 px-1 font-mono"
  >
    <math-field :value="formula" @input="updateFormula" class="h-full w-full" />
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-3";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _MathfieldElement from "mathlive";
import "mathlive";

export default {
  components: {
    NodeViewWrapper,
  },

  data() {
    return {
      formula: this.node.attrs.latex,
    };
  },

  props: nodeViewProps,

  methods: {
    updateFormula(formula) {
      this.formula = formula;
      this.updateAttrs({
        latex: formula,
      });
    },
  },
};
</script>
