<script>
export default {
  name: "RecentUsersPanel",
  props: {
    recent_users: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <section class="rounded border border-gray-100 bg-gray-50 p-2">
    <h4 class="mb-2 text-lg font-semibold">Recent Users</h4>

    <ul class="flex flex-col space-y-1">
      <li v-for="user in recent_users" :key="user.id">
        <a :href="`/admin/users/${user.id}`">{{ user.email }}</a>
      </li>
    </ul>
  </section>
</template>
