import "~/stylesheets/index.scss";
import "katex/dist/katex.css";
import "@fortawesome/fontawesome-free/css/all.css";

import katex from "katex";

import { createApp } from "vue";
import UserDashboard from "@/UserDashboard";
import AdminDashboard from "@/AdminDashboard";
import ProblemForm from "@/ProblemForm";
import ProblemShow from "@/ProblemShow";
import DocumentForm from "@/DocumentForm";
import DocumentShow from "@/DocumentShow";
import DocumentPresentation from "@/DocumentPresentation";
import AdminUserActivity from "@/AdminUserActivity";
import { MultiSelect } from "@/components/Controls";

let apps = {
  UserDashboard: UserDashboard,
  AdminDashboard: AdminDashboard,
  ProblemForm: ProblemForm,
  ProblemShow: ProblemShow,
  DocumentForm: DocumentForm,
  DocumentShow: DocumentShow,
  DocumentPresentation: DocumentPresentation,
  AdminUserActivity: AdminUserActivity,
};

Object.entries(apps).map(([name, component]) => {
  document.querySelectorAll(`[data-component="${name}"]`).forEach((element) => {
    let props = JSON.parse(element.getAttribute("data-props"));
    const app = createApp(component, props);
    // app.component("font-awesome-icon", FontAwesomeIcon);
    app.mount(element);
  });
});

window.mathify = function () {
  document
    .querySelectorAll('span[data-type="math-equation"]')
    .forEach((element) => {
      katex.render(element.textContent, element, {
        throwOnError: false,
      });
    });
};

window.multiSelectify = function () {
  document.querySelectorAll('[data-type="multi-select"]').forEach((element) => {
    let appEl = document.createElement("div");
    element.parentNode.insertBefore(appEl, element.nextSibling);
    element.setAttribute("type", "hidden");
    let props = JSON.parse(element.getAttribute("data-props"));
    const app = createApp(MultiSelect, {
      ...props,
      onUpdate: (e) => {
        element.value = e.join(",");
      },
    });
    app.mount(appEl);
  });
};

document.addEventListener("DOMContentLoaded", function () {
  window.mathify();
  window.multiSelectify();
});
