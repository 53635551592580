import MultipleChoiceWidget from "./MultipleChoiceWidget.vue";
import MultipleChoiceItemWidget from "./MultipleChoiceItemWidget.vue";

import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";

export default MultipleChoiceWidget;

export const MultipleChoiceItemExtension = Node.create({
  name: "multipleChoiceItem",

  content: "inline*",

  defining: true,

  draggable: true,

  addAttributes() {
    return {
      correct: {
        default: "false",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "li",
        getAttrs: (dom: any) => {
          if (dom.getAttribute("data-type") !== "multiple-choice-item") {
            return false;
          }

          return {
            correct: dom.getAttribute("data-correct") === "true",
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "li",
      mergeAttributes({
        "data-type": "multiple-choice-item",
        "data-correct": HTMLAttributes.correct,
      }),
      0,
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(MultipleChoiceItemWidget);
  },
});

export const MultipleChoiceExtension = Node.create({
  name: "multipleChoice",

  group: "block list",

  content: "multipleChoiceItem*",

  draggable: true,

  addAttributes() {
    return {};
  },

  parseHTML() {
    return [
      {
        tag: "ul",
        getAttrs: (dom: any) => {
          if (dom.getAttribute("data-type") !== "multiple-choice") {
            return false;
          }

          return {};
        },
      },
    ];
  },

  renderHTML() {
    return ["ul", mergeAttributes({ "data-type": "multiple-choice" }), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(MultipleChoiceWidget);
  },
});
