<script>
import { LargeTabbedList } from "@/components/Controls";

export default {
  name: "DocumentShow",
  components: { LargeTabbedList },
  props: {
    format_options: {
      type: Object,
      required: true,
    },
    teacher_html: {
      type: String,
      required: true,
    },
    student_html: {
      type: String,
      required: true,
    },
    tex: {
      type: String,
      required: true,
    },
    json: {
      type: Object,
      required: true,
    },
    json_extra: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      tabs: [
        { name: "Teacher HTML", key: "teacher_html" },
        { name: "Student HTML", key: "student_html" },
        { name: "TeX", key: "tex" },
        { name: "Format Options", key: "format_options" },
        { name: "JSON", key: "json" },
        { name: "JSON (Extra)", key: "json_extra" },
      ],
    };
  },

  methods: {
    mathify: function () {
      this.$nextTick(() => {
        window.mathify();
      });
    },
  },
};
</script>

<template>
  <div>
    <LargeTabbedList :list="tabs" @change="mathify">
      <template #tab="{ item }">
        {{ item.name }}
      </template>

      <template #panel="{ item, selected }">
        <div v-if="selected && item.key === 'teacher_html'" class="py-2">
          <section class="document-preview rounded bg-gray-50 p-2">
            <div v-html="teacher_html" />
            <hr />
            <section
              class="overflow-auto whitespace-pre rounded border border-gray-100 bg-gray-50 p-2 font-mono shadow-inner"
            >
              <div v-text="teacher_html.replaceAll('><', '>\n<')" />
            </section>
          </section>
        </div>

        <div v-if="selected && item.key === 'student_html'" class="py-2">
          <section class="document-preview rounded bg-gray-50 p-2">
            <div v-html="student_html" />
            <hr />
            <section
              class="overflow-auto whitespace-pre rounded border border-gray-100 bg-gray-50 p-2 font-mono shadow-inner"
            >
              <div v-text="student_html.replaceAll('><', '>\n<')" />
            </section>
          </section>
        </div>

        <div v-if="selected && item.key === 'tex'" class="py-2">
          <section
            class="overflow-auto whitespace-pre rounded border border-gray-100 bg-gray-50 p-2 font-mono shadow-inner"
          >
            <div v-text="tex" />
          </section>
        </div>

        <div v-if="selected && item.key === 'format_options'" class="py-2">
          <section
            class="overflow-auto whitespace-pre rounded border border-gray-100 bg-gray-50 p-2 font-mono shadow-inner"
          >
            <div v-text="format_options" />
          </section>
        </div>

        <div v-if="selected && item.key === 'json'" class="py-2">
          <section
            class="overflow-auto whitespace-pre rounded border border-gray-100 bg-gray-50 p-2 font-mono shadow-inner"
          >
            <div v-text="json" />
          </section>
        </div>

        <div v-if="selected && item.key === 'json_extra'" class="py-2">
          <section
            class="overflow-auto whitespace-pre rounded border border-gray-100 bg-gray-50 p-2 font-mono shadow-inner"
          >
            <div v-text="json_extra" />
          </section>
        </div>

        <div v-if="selected && item.key === 'extracted_answer'" class="py-2">
          <section
            class="overflow-auto whitespace-pre rounded border border-gray-100 bg-gray-50 p-2 font-mono shadow-inner"
          >
            <div v-text="extracted_answer" />
          </section>
        </div>
      </template>
    </LargeTabbedList>
  </div>
</template>
