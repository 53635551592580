<template>
  <button
    @click="
      (e) => {
        e.preventDefault();
        emit('click', e);
      }
    "
    :disabled="disabled"
    :class="classes"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  active?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits(["click"]);

const classes = computed(() => [
  "rounded",
  "px-1",
  "py-0.5",
  "text-black",
  "active:shadow-inner",
  "active:bg-gray-300",
  "h-8",
  "min-w-8",
  "flex-shrink-0",
  {
    "hover:bg-gray-200": !props.disabled && !props.active,
    "bg-gray-200": props.active,
    "bg-white": !props.active,
    "opacity-20": props.disabled,
  },
]);
</script>
