<template>
  <node-view-wrapper
    class="flex flex-row border-b border-gray-300 last:border-b-0"
  >
    <div
      class="flex w-8 grow-0 cursor-grab select-none items-center justify-center rounded-none p-1 text-center text-gray-200 transition-colors hover:text-gray-400"
      draggable="true"
      data-drag-handle=""
    >
      <i class="fa-solid fa-grip-lines"></i>
    </div>
    <div
      class="grow-0 border-l border-gray-300 p-2"
      :class="{ 'text-green-600': this.node.attrs.correct }"
    >
      <button @click.prevent="toggleCorrect">
        <i
          v-if="this.node.attrs.correct"
          class="fa-regular fa-circle-check"
        ></i>
        <i v-else class="fa-regular fa-circle"></i>
      </button>
    </div>
    <div class="grow" :class="{ 'text-green-600': this.node.attrs.correct }">
      <NodeViewContent class="p-2" />
    </div>
    <div
      class="flex grow-0 items-center justify-center border-l border-gray-300 p-1"
    >
      <WidgetButton
        @click="deleteNode"
        class="bg-red-50 text-sm text-gray-400 hover:bg-red-100"
      >
        <i class="fa-solid fa-trash-can"></i>
      </WidgetButton>
    </div>
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper, NodeViewContent } from "@tiptap/vue-3";
import WidgetButton from "@/components/CommonEditor/WidgetButton.vue";

export default {
  components: {
    NodeViewWrapper,
    NodeViewContent,
    WidgetButton,
  },

  props: nodeViewProps,

  methods: {
    toggleCorrect() {
      this.updateAttributes({
        correct: !this.node.attrs.correct,
      });
    },
  },
};
</script>
