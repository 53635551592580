<script>
import { onKeyStroke } from "@vueuse/core";

export default {
  name: "DocumentPresentation",
  props: {
    title: {
      type: String,
      required: true,
    },
    problems: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      current: -1,
    };
  },
  created: function () {
    onKeyStroke(
      "ArrowRight",
      (e) => {
        e.preventDefault();
        if (this.current < this.problems.length - 1) {
          this.current += 1;
        }
      },
      { dedupe: true },
    );

    onKeyStroke(
      "ArrowLeft",
      (e) => {
        e.preventDefault();
        if (this.current > -1) {
          this.current -= 1;
        }
      },
      { dedupe: true },
    );
  },
};
</script>

<template>
  <section class="flex h-full w-full justify-center" v-if="current === -1">
    <h1 class="mt-20 text-6xl">{{ title }}</h1>
  </section>

  <template v-for="(problem, idx) in problems" :key="idx">
    <section
      class="problem-preview flex h-full w-full flex-col gap-2"
      v-if="current === idx"
    >
      <h1 class="mb-8 text-3xl">Problem {{ idx + 1 }}</h1>
      <div class="w-full text-2xl leading-loose" v-html="problem" />
    </section>
  </template>
</template>
