import MathEquationWidget from "./MathEquationWidget.vue";

import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";

export default MathEquationWidget;

export const MathEquationExtension = Node.create({
  name: "mathEquation",

  group: "inline",

  inline: true,

  draggable: true,

  atom: true,

  addAttributes() {
    return {
      latex: {
        default: "y=mx+c",
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span",
        getAttrs: (dom: any) => {
          if (dom.getAttribute("data-type") !== "math-equation") {
            return false;
          }
          return {
            latex: dom.getAttribute("data-latex"),
          };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes({
        "data-type": "math-equation",
        "data-latex": HTMLAttributes.latex,
      }),
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(MathEquationWidget);
  },
});
