<template>
  <WidgetWrapper v-bind="$props">
    <template #title>
      <template v-if="correctItemCount > 1">Multi-Select</template>
      <template v-else>Multiple Choice</template>
      ({{ correctItemCount }} / {{ itemCount }})
    </template>

    <template #buttons>
      <WidgetButton @click.stop="shuffleItmes">
        <i class="fa-solid fa-random"></i>
      </WidgetButton>
      <WidgetButton
        class="bg-green-50 text-green-800 hover:bg-green-100"
        @click.stop="addItem"
      >
        <i class="fa-solid fa-circle-plus"></i>
      </WidgetButton>
    </template>

    <template #default>
      <NodeViewContent />
    </template>
  </WidgetWrapper>
</template>

<script>
import { nodeViewProps, NodeViewContent } from "@tiptap/vue-3";
import WidgetButton from "@/components/CommonEditor/WidgetButton.vue";
import WidgetWrapper from "@/components/CommonEditor/WidgetWrapper.vue";

export default {
  components: {
    NodeViewContent,
    WidgetWrapper,
    WidgetButton,
  },

  props: nodeViewProps,

  methods: {
    addItem() {
      this.editor
        .chain()
        .insertContentAt(this.getPos() + this.node.nodeSize - 1, {
          type: "multipleChoiceItem",
          attrs: { correct: false },
          content: [],
        })
        .run();
    },

    shuffleItmes() {
      let items = this.node.content.content
        .map((item) => item.toJSON())
        .sort(() => Math.random() - 0.5);
      this.editor
        .chain()
        .deleteRange({
          from: this.getPos() + 1,
          to: this.getPos() + this.node.nodeSize,
        })
        .run();

      items.forEach((item) => {
        this.editor
          .chain()
          .insertContentAt(this.getPos() + 1, item)
          .run();
      });
    },
  },

  computed: {
    itemCount() {
      return this.node.childCount;
    },

    correctItemCount() {
      return this.node.content.content.filter((item) => item.attrs.correct)
        .length;
    },
  },
};
</script>
