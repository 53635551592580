<script>
import CalHeatmap from "cal-heatmap";
import Legend from "cal-heatmap/plugins/Legend";
import Tooltip from "cal-heatmap/plugins/Tooltip";

import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";

dayjs.extend(relativeTime);
dayjs.extend(duration);

import "cal-heatmap/cal-heatmap.css";

export default {
  name: "AdminUserActivity",
  props: {
    activity: {
      type: Array,
      required: true,
    },
    last_24_hours: {
      type: Number,
      required: true,
    },
    last_7_days: {
      type: Number,
      required: true,
    },
  },
  computed: {
    last24HoursHumanized() {
      return dayjs.duration(this.last_24_hours, "seconds").humanize();
    },
    last7DaysHumanized() {
      return dayjs.duration(this.last_7_days, "seconds").humanize();
    },
  },
  mounted() {
    const cal = new CalHeatmap();
    cal.paint(
      {
        itemSelector: this.$refs.heatmap,
        range: 10,
        domain: {
          type: "day",
        },
        subDomain: { type: "hour" },
        date: {
          start: new Date(this.activity[0].from),
          end: new Date(this.activity[this.activity.length - 1].to),
        },
        data: {
          source: this.activity,
          x: (d) => new Date(d.from),
          y: (d) => (d.percentage / 100) * 60,
          groupY: "sum",
        },
        scale: {
          color: {
            // Try some values: Purples, Blues, Turbo, Magma, etc ...
            scheme: "Purples",
            type: "linear",
            domain: [0, 60],
          },
        },
      },
      [
        [
          Legend,
          {
            label: "Minutes",
          },
        ],
        [
          Tooltip,
          {
            text: function (date, value, dayjsDate) {
              return (
                (value ? `${value.toFixed(0)} minutes` : "No data") +
                " at " +
                dayjsDate.format("HH:mm")
              );
            },
          },
        ],
      ],
    );
  },
};
</script>
<template>
  <div>
    <h1>Active for about {{ last24HoursHumanized }} in the last 24 hours.</h1>
    <h1>Active for about {{ last7DaysHumanized }} in the last 7 days.</h1>
    <div ref="heatmap"></div>
  </div>
</template>
