<template>
  <WidgetWrapper v-bind="$props">
    <template #title>
      <template v-if="node.attrs.problem"
        >{{ problemType || "Problem" }} #{{ node.attrs.problem }}</template
      ><template v-else>Problem</template>
    </template>

    <template #buttons>
      <input
        type="number"
        :value="problemPoints"
        :step="1"
        :min="0"
        @input="
          (e) => {
            setPoints(e.target.valueAsNumber);
          }
        "
        class="w-16 rounded px-1 py-0.5"
      />
      <EditorButton @click="showProblemBrowserModal = true">
        <template v-if="!node.attrs.problem"> Set Problem</template
        ><template v-else>Replace Problem</template>
      </EditorButton>
    </template>

    <template #default>
      <div class="p-2">
        <h1
          class="mb-4 text-lg font-semibold last:mb-0"
          v-if="!node.attrs.problem"
        >
          No problem set
        </h1>
        <section v-if="loading" class="problem-preview rounded bg-gray-50 p-4">
          <p>Loading...</p>
        </section>
        <template v-else>
          <section
            v-if="teacherHTML"
            class="problem-preview pointer-events-none rounded bg-gray-50 p-2"
          >
            <div v-html="teacherHTML" />
          </section>
        </template>
      </div>
    </template>
  </WidgetWrapper>

  <Teleport to="#modal">
    <ModalWrapper
      v-if="showProblemBrowserModal"
      @close="showProblemBrowserModal = false"
    >
      <template #title> Problems </template>

      <template #default>
        <ProblemBrowser
          @problem="
            (problem) => {
              selectedProblem = problem;
            }
          "
        />
      </template>

      <template #actions>
        <a
          href="#"
          @click.prevent="
            showProblemBrowserModal = false;
            selectedProblem = null;
          "
        >
          Cancel
        </a>

        <EditorButton
          :disabled="!selectedProblem"
          @click="
            setPoints(selectedProblem.points);
            setProblem(selectedProblem.code);
          "
          class="btn btn-primary"
        >
          Insert Problem
        </EditorButton>
      </template>
    </ModalWrapper>
  </Teleport>
</template>

<script>
import { nodeViewProps } from "@tiptap/vue-3";
import EditorButton from "@/components/CommonEditor/EditorButton.vue";
import WidgetWrapper from "@/components/CommonEditor/WidgetWrapper.vue";
import ModalWrapper from "@/components/ModalWrapper.vue";
import ProblemBrowser from "@/components/ProblemBrowser/ProblemBrowser.vue";

export default {
  name: "ProblemWidget",

  components: {
    EditorButton,
    WidgetWrapper,
    ModalWrapper,
    ProblemBrowser,
  },

  props: nodeViewProps,

  data: function () {
    return {
      problemCode: this.node.attrs.problem,
      problemPoints: this.node.attrs.points,
      teacherHTML: null,
      problemType: null,
      loading: false,
      showProblemBrowserModal: false,
      selectedProblem: null,
    };
  },

  methods: {
    setPoints(points) {
      this.problemPoints = points;
      this.updateAttributes({
        problem: this.problemCode,
        points: this.problemPoints,
      });
    },
    setProblem(problemCode) {
      this.problemCode = problemCode;
      this.updateAttributes({
        problem: this.problemCode,
        points: this.problemPoints,
      });
      this.showProblemBrowserModal = false;
      this.selectedProblem = null;
    },
    fetchProblem() {
      this.loading = true;
      fetch(`/api/problems/${this.node.attrs.problem}`)
        .then((response) => response.json())
        .then((data) => {
          this.teacherHTML = data.html;
          this.problemType = data.human_name;
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error:", error);
          this.loading = false;
        });
    },
  },

  watch: {
    "node.attrs.problem": function () {
      this.fetchProblem();
    },
  },

  mounted() {
    if (this.node.attrs.problem) {
      this.fetchProblem();
    } else {
      this.showProblemBrowserModal = true;
    }
  },
};
</script>
