import ImageWidget from "./ImageWidget.vue";
export default ImageWidget;

import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";

export const ImageExtension = Node.create({
  name: "image",

  group: "block",

  atom: true,

  draggable: true,

  addAttributes() {
    return {
      image: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "img",
        getAttrs: (dom: any) => {
          if (dom.getAttribute("data-type") !== "image") {
            return false;
          }
          const image = dom.getAttribute("data-image");
          return { image };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "object",
      mergeAttributes({
        "data-type": "image",
        "data-image": HTMLAttributes.image,
      }),
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(ImageWidget);
  },
});
