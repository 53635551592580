<script>
import { debounce, includes } from "lodash";

export default {
  name: "MultiSelect",

  props: {
    url: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
      required: false,
    },
  },

  data: function () {
    return {
      items: this.value,
      autocompleteList: [],
    };
  },

  methods: {
    autocomplete: debounce(function (e) {
      fetch(this.url.replace("QUERY", e.target.value))
        .then((res) => res.json())
        .then((data) => {
          this.autocompleteList = data.data || [];
        });
    }, 150),
    keypress: function (e) {
      if (e.key === "Enter") {
        e.preventDefault();
        let selected = this.autocompleteList.find((item) => item.selected);
        if (selected) {
          this.addItem(selected.name);
        } else {
          this.addItem(this.$refs.input.value);
        }
      }

      this.$refs.input.focus();
    },

    keydown: function (e) {
      if (e.key === "Backspace" && this.$refs.input.value === "") {
        e.preventDefault();
        this.items.pop();
      }

      if (e.key == "Escape") {
        e.preventDefault();
        this.$refs.input.value = "";
        this.autocompleteList = [];
      }

      if (e.key == "ArrowDown") {
        if (this.autocompleteList.length === 0) {
          return;
        }
        e.preventDefault();
        const idx = this.autocompleteList.findIndex((item) => item.selected);
        if (idx === -1) {
          this.autocompleteList[0].selected = true;
        } else {
          this.autocompleteList[idx].selected = false;
          this.autocompleteList[
            (idx + 1) % this.autocompleteList.length
          ].selected = true;
        }
      }

      if (e.key == "ArrowUp") {
        if (this.autocompleteList.length === 0) {
          return;
        }
        e.preventDefault();
        const idx = this.autocompleteList.findIndex((item) => item.selected);
        if (idx === -1) {
          this.autocompleteList[this.autocompleteList.length - 1].selected =
            true;
        } else {
          this.autocompleteList[idx].selected = false;
          this.autocompleteList[
            (idx - 1 + this.autocompleteList.length) %
              this.autocompleteList.length
          ].selected = true;
        }
      }
    },

    blur: function (e) {
      setTimeout(() => {
        e.target.value = "";
        this.autocompleteList = [];
      }, 200);
    },

    removeItem: function (idx) {
      this.items = this.items.filter((_, i) => i !== idx);
    },

    addItem: function (item) {
      if (item !== "" && !includes(this.items, item)) {
        this.items.push(item);
      }
      this.$refs.input.value = "";
      this.autocompleteList = [];
    },
  },

  watch: {
    items: {
      handler: function (newVal) {
        this.$emit("update", newVal);
      },
      deep: true,
    },
  },
};
</script>

<template>
  <div
    class="relative flex w-full flex-row gap-2 rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cyan-600 sm:text-sm sm:leading-6"
  >
    <div
      class="rounded border bg-gray-50 px-1 text-sm shadow transition-colors hover:bg-gray-100"
      v-for="(item, idx) in items"
      :key="idx"
    >
      {{ item }} <a href="#" @click.prevent="removeItem(idx)">&times;</a>
    </div>
    <div class="flex-grow">
      <input
        ref="input"
        type="text"
        class="grow border-0 p-0 focus:ring-0"
        @keypress="keypress"
        @keydown="keydown"
        @input="autocomplete"
        @blur="blur"
        @focus="autocomplete"
      />
      <div class="absolute z-30 flex flex-col gap-2 rounded border bg-gray-50">
        <div
          class="cursor-pointer rounded border-2 border-transparent px-2 py-1 transition-colors hover:border-cyan-400 hover:bg-cyan-50"
          @click="addItem(item.name)"
          :class="{
            'border-cyan-400 bg-cyan-50': item.selected,
          }"
          v-for="(item, idx) in autocompleteList"
          :key="idx"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>
