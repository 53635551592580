<template>
  <node-view-wrapper
    v-bind="$props"
    class="flex min-h-5 flex-col rounded-md border-0 bg-white p-px shadow-sm ring-1 ring-inset ring-gray-300"
  >
    <section
      class="flex h-10 flex-row items-center rounded-t border-0 border-b border-gray-300 bg-gray-50 font-sans text-sm"
    >
      <h1
        draggable="true"
        data-drag-handle=""
        class="flex h-full grow cursor-move items-center px-2 font-semibold"
      >
        <slot name="title" />
      </h1>

      <div class="justify-end">
        <EditorToolbar>
          <slot name="buttons" />

          <EditorSeparator v-if="hasButtons" />

          <WidgetButton v-if="hasToolbar" @click="toggleToolbar">
            <i class="fa-solid fa-bars"></i>
          </WidgetButton>

          <WidgetButton
            class="rounded-full bg-red-50 hover:bg-red-100"
            @click="deleteNode"
          >
            <i class="fa-solid fa-xmark"></i>
          </WidgetButton>
        </EditorToolbar>
      </div>
    </section>

    <section
      v-if="hasToolbar && showToolbar"
      class="flex flex-col gap-1 border-b border-gray-500 bg-gray-300 p-1 font-sans shadow-inner"
    >
      <slot name="toolbar" />
    </section>

    <slot name="default" />
  </node-view-wrapper>
</template>

<script>
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-3";
import WidgetButton from "@/components/CommonEditor/WidgetButton.vue";
import EditorSeparator from "@/components/CommonEditor/EditorSeparator.vue";
import EditorToolbar from "@/components/CommonEditor/EditorToolbar.vue";

export default {
  components: {
    NodeViewWrapper,
    WidgetButton,
    EditorSeparator,
    EditorToolbar,
  },

  data() {
    return {
      showToolbar: false,
    };
  },

  props: nodeViewProps,

  methods: {
    toggleToolbar() {
      this.showToolbar = !this.showToolbar;
    },
  },

  computed: {
    hasToolbar() {
      return this.$slots.toolbar;
    },
    hasButtons() {
      return this.$slots.buttons;
    },
  },
};
</script>
