<script>
import { tv } from "tailwind-variants";

export default {
  props: {
    problem: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["click"],

  methods: {
    handleClick(e) {
      e.preventDefault();
      this.$emit("click", this.problem);
    },
  },
  computed: {
    panelStyle() {
      return tv({
        base: "block break-inside-avoid rounded  border p-2 shadow transition-shadow hover:shadow-lg",
        variants: {
          selected: {
            true: "border-blue-300 bg-blue-50",
            false: "border-gray-300 bg-white",
          },
        },
      });
    },
  },
};
</script>

<template>
  <div :class="panelStyle({ selected })">
    <a href="#" @click="handleClick">
      <section
        v-if="problem.html"
        class="problem-preview pointer-events-none bg-gray-50 p-2"
      >
        <div v-html="problem.html" />
      </section>
    </a>
  </div>
</template>
