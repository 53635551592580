<script>
import RecentDocumentsPanel from "./components/RecentDocumentsPanel.vue";

export default {
  name: "UserDashboard",
  components: {
    RecentDocumentsPanel,
  },
  props: {
    documents: {
      type: Array,
      required: true,
    },
  },
};
</script>
<template>
  <section
    class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
  >
    <RecentDocumentsPanel :documents="documents" />
  </section>
</template>
