<script setup lang="ts">
import { computed } from "vue";
const props = defineProps<{
  disabled?: boolean;
}>();

const classes = computed(() => ({
  "border px-2 flex items-center shadow-button bg-gray-100 border-gray-400 ":
    true,
  "bg-gray-50 text-gray-400 cursor-not-allowed": props.disabled,
  "hover:bg-gray-200 active:bg-gray-300 text-gray-700 bg-gray-100 cursor-pointer focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:z-10":
    !props.disabled,
}));
</script>

<template>
  <button v-bind="$attrs" :disabled="disabled" :class="classes" type="button">
    <slot />
  </button>
</template>
