<template>
  <EditorFrame :editor="editor">
    <EditorToolbarGroup>
      <EditorButton
        @click="editor.chain().focus().toggleBold().run()"
        :disabled="!editor.can().chain().focus().toggleBold().run()"
        :active="editor.isActive('bold')"
      >
        <i class="fa-solid fa-bold"></i>
      </EditorButton>
      <EditorButton
        @click="editor.chain().focus().toggleItalic().run()"
        :disabled="!editor.can().chain().focus().toggleItalic().run()"
        :active="editor.isActive('italic')"
      >
        <i class="fa-solid fa-italic"></i>
      </EditorButton>
      <EditorButton
        @click="editor.chain().focus().toggleUnderline().run()"
        :disabled="!editor.can().chain().focus().toggleUnderline().run()"
        :active="editor.isActive('underline')"
      >
        <i class="fa-solid fa-underline"></i>
      </EditorButton>
      <EditorButton
        @click="editor.chain().focus().toggleStrike().run()"
        :disabled="!editor.can().chain().focus().toggleStrike().run()"
        :active="editor.isActive('strike')"
      >
        <i class="fa-solid fa-strikethrough"></i>
      </EditorButton>
    </EditorToolbarGroup>
    <EditorSeparator />
    <EditorToolbarGroup>
      <EditorButton
        @click="editor.chain().focus().toggleBulletList().run()"
        :active="editor.isActive('bulletList')"
      >
        <i class="fa-solid fa-list-dots"></i>
      </EditorButton>
      <EditorButton
        @click="editor.chain().focus().toggleOrderedList().run()"
        :active="editor.isActive('orderedList')"
      >
        <i class="fa-solid fa-list-numeric"></i>
      </EditorButton>
      <EditorButton @click="editor.chain().focus().setHorizontalRule().run()">
        <i class="fa-solid fa-minus"></i>
      </EditorButton>
      <EditorButton @click="editor.chain().focus().setHardBreak().run()">
        <i class="fa-solid fa-arrow-turn-down"></i>
      </EditorButton>
    </EditorToolbarGroup>
    <EditorSeparator />
    <EditorToolbarGroup>
      <EditorButton
        @click="editor.chain().focus().undo().run()"
        :disabled="!editor.can().chain().focus().undo().run()"
      >
        <i class="fa-solid fa-undo"></i>
      </EditorButton>
      <EditorButton
        @click="editor.chain().focus().redo().run()"
        :disabled="!editor.can().chain().focus().redo().run()"
      >
        <i class="fa-solid fa-redo"></i>
      </EditorButton>
    </EditorToolbarGroup>
    <EditorSeparator />
    <EditorToolbarGroup>
      <EditorButton
        @click="
          editor
            .chain()
            .focus()
            .insertContent({
              type: 'problem',
              attrs: {},
            })
            .run()
        "
        :disabled="editor.isActive('problem')"
      >
        <i class="fa-solid fa-question"></i>
      </EditorButton>
      <EditorButton
        @click="
          editor
            .chain()
            .focus()
            .insertContent({
              type: 'image',
              attrs: {},
            })
            .run()
        "
        :disabled="editor.isActive('image')"
      >
        <i class="fa-solid fa-image"></i>
      </EditorButton>
    </EditorToolbarGroup>
  </EditorFrame>
</template>

<script>
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import { Editor } from "@tiptap/vue-3";
import EditorToolbarGroup from "@/components/CommonEditor/EditorToolbarGroup.vue";
import EditorButton from "@/components/CommonEditor/EditorButton.vue";
import EditorSeparator from "@/components/CommonEditor/EditorSeparator.vue";
import EditorFrame from "@/components/CommonEditor/EditorFrame.vue";

import { ProblemExtension } from "@/components/DocumentEditor/widgets/Problem";
import { ImageExtension } from "@/components/CommonEditor/widgets/Image";

import { isEmpty } from "lodash";

export default {
  name: "DocumentEditor",
  components: {
    EditorButton,
    EditorSeparator,
    EditorToolbarGroup,
    EditorFrame,
  },

  props: {
    content: {
      type: String,
      default: "",
      required: false,
    },
    "content:json": {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },

  emits: ["update:content", "update:json"],

  data() {
    return {
      editor: null,
    };
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3, 4],
          },
        }),
        Underline,
        ProblemExtension,
        ImageExtension,
      ],
    });
    if (this.content !== "") {
      this.editor.commands.setContent(this.content);
    } else if (!isEmpty(this["content:json"])) {
      this.editor.commands.setContent(this["content:json"]);
    } else {
      this.editor.commands.setContent("<p>Start typing...</p>");
    }

    this.$emit("update:json", this.editor.getJSON());

    this.editor.on("update", () => {
      this.$emit("update:content", this.editor.getHTML());
      this.$emit("update:json", this.editor.getJSON());
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style scoped lang="scss">
:deep([contenteditable="true"]) {
  //caret-color: purple;

  &:focus-visible {
    outline: none;
  }
}

/* Basic editor styles */
:deep(.tiptap) {
  height: 100%;

  > * + * {
    margin-top: 0.75em;
  }

  ul {
    list-style: disc;
  }

  ol {
    list-style: decimal;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
</style>
