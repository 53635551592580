<template>
  <WidgetWrapper v-bind="$props">
    <template #title> Image </template>

    <template #buttons>
      <EditorButton @click="showImageBrowserModal = true"
        ><template v-if="!node.attrs.image"> Set Image</template
        ><template v-else>Replace Image</template>
      </EditorButton>
    </template>

    <template #default>
      <h1 class="mb-4 text-lg font-semibold last:mb-0" v-if="!node.attrs.image">
        No image set
      </h1>
      <section v-if="loading" class="image-preview rounded bg-gray-50 p-4">
        <p>Loading...</p>
      </section>
      <template v-else>
        <section v-if="previewURL" class="image-preview rounded bg-gray-50 p-2">
          <img :src="previewURL" alt="Image" />
        </section>
      </template>
    </template>
  </WidgetWrapper>

  <Teleport to="#modal">
    <ModalWrapper
      v-if="showImageBrowserModal"
      @close="showImageBrowserModal = false"
    >
      <template #title> Images </template>

      <template #default>
        <ImageBrowser @image="(image) => (selectedImage = image)" />
      </template>

      <template #actions>
        <a
          href="#"
          @click.prevent="
            showImageBrowserModal = false;
            selectedImage = null;
          "
        >
          Cancel
        </a>

        <EditorButton
          :disabled="!selectedImage"
          @click="setImage(selectedImage)"
          class="btn btn-primary"
        >
          Insert Image
        </EditorButton>
      </template>
    </ModalWrapper>
  </Teleport>
</template>

<script>
import { nodeViewProps } from "@tiptap/vue-3";
import EditorButton from "@/components/CommonEditor/EditorButton.vue";
import WidgetWrapper from "@/components/CommonEditor/WidgetWrapper.vue";
import ModalWrapper from "@/components/ModalWrapper.vue";
import ImageBrowser from "@/components/ImageBrowser/ImageBrowser.vue";

export default {
  name: "ImageWidget",
  components: {
    EditorButton,
    WidgetWrapper,
    ModalWrapper,
    ImageBrowser,
  },

  props: nodeViewProps,

  data: function () {
    return {
      imageCode: this.node.attrs.image,
      previewURL: null,
      imageType: null,
      loading: false,
      showImageBrowserModal: false,
      selectedImage: null,
    };
  },

  methods: {
    setImage(image) {
      this.imageCode = image.code;
      this.updateAttributes({
        image: this.imageCode,
      });
      this.showImageBrowserModal = false;
      this.selectedImage = null;
    },
    fetchImage() {
      this.loading = true;
      fetch(`/api/images/${this.node.attrs.image}`)
        .then((response) => response.json())
        .then((data) => {
          this.previewURL = data.preview_url;
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error:", error);
          this.loading = false;
        });
    },
  },

  watch: {
    "node.attrs.image": function () {
      if (this.node.attrs.image) {
        this.fetchImage();
      }
    },
  },

  mounted() {
    if (this.node.attrs.image) {
      this.fetchImage();
    } else {
      this.showImageBrowserModal = true;
    }
  },
};
</script>
