<script>
export default {
  name: "ProblemShow",
  props: {
    metadata: {
      type: Object,
      required: true,
    },
    teacher_html: {
      type: String,
      required: true,
    },
    student_html: {
      type: String,
      required: true,
    },
    tex: {
      type: String,
      required: true,
    },
    json: {
      type: Object,
      required: true,
    },
    json_extra: {
      type: Object,
      required: true,
    },
    extracted_answer: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      activeTab: "metadata",
      tabs: [
        { name: "Metadata", key: "metadata" },
        { name: "Teacher HTML", key: "teacher_html" },
        { name: "Student HTML", key: "student_html" },
        { name: "TeX", key: "tex" },
        { name: "JSON", key: "json" },
        { name: "JSON (Extra)", key: "json_extra" },
        { name: "Extracted Answer", key: "extracted_answer" },
      ],
    };
  },
};
</script>

<template>
  <div>
    <div class="border-b border-gray-200">
      <nav class="-mb-px flex space-x-8" aria-label="Tabs">
        <a
          v-for="(tab, idx) in tabs"
          :key="idx"
          href="#"
          :class="{
            'border-cyan-700 text-cyan-800': activeTab === tab.key,
            'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700':
              activeTab !== tab.key,
          }"
          @click.prevent="activeTab = tab.key"
          class="whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium"
          >{{ tab.name }}</a
        >
      </nav>
    </div>

    <div v-if="activeTab === 'metadata'" class="py-2">
      <section class="rounded bg-gray-50 p-2">
        {{ metadata }}
      </section>
    </div>

    <div v-if="activeTab === 'teacher_html'" class="py-2">
      <section class="problem-preview rounded bg-gray-50 p-2">
        <div v-html="teacher_html" />
        <hr />
        <section
          class="overflow-auto whitespace-pre rounded border border-gray-100 bg-gray-50 p-2 font-mono shadow-inner"
        >
          <div v-text="teacher_html.replaceAll('><', '>\n<')" />
        </section>
      </section>
    </div>

    <div v-if="activeTab === 'student_html'" class="py-2">
      <section class="problem-preview rounded bg-gray-50 p-2">
        <div v-html="student_html" />
        <hr />
        <section
          class="overflow-auto whitespace-pre rounded border border-gray-100 bg-gray-50 p-2 font-mono shadow-inner"
        >
          <div v-text="student_html.replaceAll('><', '>\n<')" />
        </section>
      </section>
    </div>

    <div v-if="activeTab === 'tex'" class="py-2">
      <section
        class="overflow-auto whitespace-pre rounded border border-gray-100 bg-gray-50 p-2 font-mono shadow-inner"
      >
        <div v-text="tex" />
      </section>
    </div>

    <div v-if="activeTab === 'json'" class="py-2">
      <section
        class="overflow-auto whitespace-pre rounded border border-gray-100 bg-gray-50 p-2 font-mono shadow-inner"
      >
        <div v-text="json" />
      </section>
    </div>

    <div v-if="activeTab === 'json_extra'" class="py-2">
      <section
        class="overflow-auto whitespace-pre rounded border border-gray-100 bg-gray-50 p-2 font-mono shadow-inner"
      >
        <div v-text="json_extra" />
      </section>
    </div>

    <div v-if="activeTab === 'extracted_answer'" class="py-2">
      <section
        class="overflow-auto whitespace-pre rounded border border-gray-100 bg-gray-50 p-2 font-mono shadow-inner"
      >
        <div v-text="extracted_answer" />
      </section>
    </div>
  </div>
</template>
