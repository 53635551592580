<script>
import { BaseButton } from "@/components/Controls";
import ProblemFilter from "./ProblemFilter.vue";
import ProblemPanel from "./ProblemPanel.vue";
import { each } from "lodash";
export default {
  name: "ProblemBrowser",
  components: { BaseButton, ProblemFilter, ProblemPanel },
  emits: ["problem"],
  data: function () {
    return {
      selectedProblem: null,
      problemBrowser: {
        loading: true,
        data: [],
        metadata: {},
      },
      problemCode: null,
    };
  },
  methods: {
    filterProblems: function (filters) {
      let f = [];
      each(filters, (value, scope) => {
        each(value, (v) => {
          let k = `${scope}[]`;
          f.push([k, v]);
        });
      });
      this.fetchProblems(`/api/problems/?${new URLSearchParams(f).toString()}`);
    },
    fetchProblems: function (url) {
      this.problemBrowser.loading = true;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.problemBrowser = data;
          this.problemBrowser.loading = false;
        });
    },
  },
  mounted: function () {
    this.fetchProblems("/api/problems/");
  },
};
</script>
<template>
  <div>
    <div class="flex flex-row gap-2">
      <ProblemFilter
        class="w-1/4 rounded bg-gray-50 p-2"
        :selected-filters="problemBrowser.metadata.filters"
        @filter="filterProblems"
      />
      <div v-if="problemBrowser.loading">Loading...</div>
      <div v-else class="flex w-3/4 flex-col justify-between">
        <div class="columns-1 gap-4 space-y-4 lg:columns-2">
          <ProblemPanel
            v-for="problem in problemBrowser.data"
            :key="problem.id"
            :problem="problem"
            :selected="selectedProblem === problem"
            @click="
              () => {
                selectedProblem = selectedProblem === problem ? null : problem;
                $emit('problem', selectedProblem);
              }
            "
          />
        </div>
        <nav class="flex flex-row justify-center gap-2">
          <BaseButton
            :disabled="!problemBrowser.metadata.first"
            @click="fetchProblems(problemBrowser.metadata.first)"
            class="btn btn-primary"
            ><i class="fa-solid fa-arrow-left"></i
          ></BaseButton>
          <BaseButton
            :disabled="!problemBrowser.metadata.previous"
            @click="fetchProblems(problemBrowser.metadata.previous)"
            class="btn btn-secondary"
            ><i class="fa-solid fa-chevron-left"></i
          ></BaseButton>
          <BaseButton
            :disabled="!problemBrowser.metadata.next"
            @click="fetchProblems(problemBrowser.metadata.next)"
            class="btn btn-secondary"
            ><i class="fa-solid fa-chevron-right"></i
          ></BaseButton>

          <BaseButton
            :disabled="!problemBrowser.metadata.last"
            @click="fetchProblems(problemBrowser.metadata.last)"
            class="btn btn-primary"
            ><i class="fa-solid fa-arrow-right"></i
          ></BaseButton>
        </nav>
      </div>
    </div>
  </div>
</template>
