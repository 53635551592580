<template>
  <WidgetWrapper v-bind="$props">
    <template #title> Clickable Group </template>

    <template #buttons>
      <WidgetButton
        class="bg-green-50 text-green-800 hover:bg-green-100"
        :active="editor.isActive('clickableGroupItem', { correct: true })"
        @click="toggleClickableGroupItem({ correct: true })"
      >
        <i class="fa-solid fa-circle-check"></i>
      </WidgetButton>
      <WidgetButton
        :active="editor.isActive('clickableGroupItem', { correct: false })"
        class="bg-red-50 text-red-800 hover:bg-red-100"
        @click="toggleClickableGroupItem({ correct: false })"
      >
        <i class="fa-solid fa-circle-xmark"></i>
      </WidgetButton>
    </template>

    <template #default>
      <NodeViewContent class="m-2" />
    </template>
  </WidgetWrapper>
</template>

<script>
import { nodeViewProps, NodeViewContent } from "@tiptap/vue-3";
import WidgetButton from "@/components/CommonEditor/WidgetButton.vue";
import WidgetWrapper from "@/components/CommonEditor/WidgetWrapper.vue";

export default {
  components: {
    WidgetButton,
    NodeViewContent,
    WidgetWrapper,
  },

  props: nodeViewProps,

  methods: {
    toggleClickableGroupItem(attrs) {
      this.editor.chain().focus().toggleClickableGroupItem(attrs).run();
    },
  },
};
</script>

<style scoped>
:deep([data-type="clickable-group-item"][data-correct="false"]) {
  background-color: #ffcccc;
}

:deep([data-type="clickable-group-item"][data-correct="true"]) {
  background-color: #ccffcc;
}
</style>
