<script>
import { BaseButton } from "@/components/Controls";
export default {
  name: "ImageBrowser",
  components: { BaseButton },
  emits: ["image"],
  data: function () {
    return {
      imageUpload: {
        loading: false,
      },
      imageBrowser: {
        loading: false,
        data: [],
        metadata: {},
      },
      selectedImage: null,
    };
  },
  methods: {
    fetchImages(url) {
      this.imageBrowser.loading = true;
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.imageBrowser = data;
          this.imageBrowser.loading = false;
        });
    },

    uploadImage(event) {
      this.imageUpload.loading = true;
      const formData = new FormData();
      formData.append("image[file]", event.target.files[0]);
      fetch("/api/images/", {
        method: "POST",
        body: formData,
        headers: {
          "X-CSRF-Token": document.querySelector("[name='csrf-token']").content,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.imageBrowser.data = [data, ...this.imageBrowser.data];
          this.selectedImage = data;
          this.imageUpload.loading = false;
          this.$emit("image", data);
        })
        .catch((error) => {
          this.imageUpload.loading = false;
          console.error(error);
        });
    },
  },
  mounted() {
    this.fetchImages("/api/images/");
  },
};
</script>

<template>
  <section class="p-2">
    <div>
      <h2 class="text-lg font-semibold">Image Upload</h2>
      <form>
        <label
          class="block cursor-pointer rounded border border-dashed border-gray-400 p-2"
          >Chose an image to upload...
          <input
            class="sr-only"
            type="file"
            name="image"
            @change="uploadImage"
          />
        </label>
      </form>
    </div>
    <div v-if="imageBrowser.loading">Loading...</div>
    <div v-else>
      <h2 class="text-lg font-semibold">Image Browser</h2>
      <div class="flex flex-row flex-wrap gap-2">
        <div
          v-for="image in imageBrowser.data"
          :key="image.id"
          class="image-preview rounded p-2"
          :class="{
            'bg-blue-100': selectedImage === image,
            'bg-gray-50': selectedImage !== image,
          }"
        >
          <a
            href="#"
            @click.prevent="
              () => {
                selectedImage = selectedImage === image ? null : image;
                $emit('image', selectedImage);
              }
            "
            ><img :src="image.thumbnail_url" alt="Image"
          /></a>
        </div>
      </div>
      <nav class="flex flex-row justify-center gap-2">
        <BaseButton
          :disabled="!imageBrowser.metadata.first"
          @click="fetchImages(imageBrowser.metadata.first)"
          class="btn btn-primary"
          ><i class="fa-solid fa-arrow-left"></i
        ></BaseButton>
        <BaseButton
          :disabled="!imageBrowser.metadata.previous"
          @click="fetchImages(imageBrowser.metadata.previous)"
          class="btn btn-secondary"
          ><i class="fa-solid fa-chevron-left"></i
        ></BaseButton>
        <BaseButton
          :disabled="!imageBrowser.metadata.next"
          @click="fetchImages(imageBrowser.metadata.next)"
          class="btn btn-secondary"
          ><i class="fa-solid fa-chevron-right"></i
        ></BaseButton>

        <BaseButton
          :disabled="!imageBrowser.metadata.last"
          @click="fetchImages(imageBrowser.metadata.last)"
          class="btn btn-primary"
          ><i class="fa-solid fa-arrow-right"></i
        ></BaseButton>
      </nav>
    </div>
  </section>
</template>
