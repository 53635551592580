<template>
  <WidgetWrapper v-bind="$props">
    <template #title> Answer Field Group </template>

    <template #buttons>
      <WidgetButton
        :active="editor.isActive('answerField')"
        @click="toggleAnswerField()"
      >
        <i class="fa-solid fa-rectangle-list"></i>
      </WidgetButton>
    </template>

    <template #default>
      <NodeViewContent class="m-2" />
    </template>
  </WidgetWrapper>
</template>

<script>
import { nodeViewProps, NodeViewContent } from "@tiptap/vue-3";
import WidgetButton from "@/components/CommonEditor/WidgetButton.vue";
import WidgetWrapper from "@/components/CommonEditor/WidgetWrapper.vue";

export default {
  components: {
    WidgetButton,
    NodeViewContent,
    WidgetWrapper,
  },

  props: nodeViewProps,

  methods: {
    toggleAnswerField(attrs) {
      this.editor.chain().focus().toggleAnswerField(attrs).run();
    },
  },
};
</script>

<style scoped>
:deep([data-type="answer-field"]) {
  @apply rounded border bg-white px-2 py-1 shadow-inner;
}
</style>
