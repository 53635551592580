<script>
export default {
  name: "RecentDocumentsPanel",
  props: {
    documents: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <section class="rounded border border-gray-100 bg-gray-50 p-2">
    <section class="flex flex-row items-center justify-between">
      <h4 class="mb-2 text-lg font-semibold">Recent Documents</h4>
      <a href="/documents/new" class="btn btn-primary">New Document</a>
    </section>

    <ul class="flex flex-col space-y-1">
      <li v-for="document in documents" :key="document.id">
        <a :href="`/documents/${document.code}`">{{ document.title }}</a>
      </li>
    </ul>
    <div class="mt-4">
      <a href="/documents" class="block text-sm text-cyan-500">View all</a>
    </div>
  </section>
</template>
