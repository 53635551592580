<template>
  <div
    class="flex flex-col rounded-md border-0 bg-white p-px shadow-sm ring-1 ring-inset ring-gray-300"
  >
    <EditorToolbar
      class="rounded-t border-b border-gray-300 bg-gray-50"
      v-if="editor"
    >
      <slot></slot>
    </EditorToolbar>
    <div class="flex-grow p-2 shadow-inner">
      <editor-content class="h-full min-h-72" :editor="editor" />
    </div>
  </div>
</template>

<script>
import { EditorContent } from "@tiptap/vue-3";
import EditorToolbar from "@/components/CommonEditor/EditorToolbar.vue";
export default {
  name: "EditorFrame",
  components: {
    EditorContent,
    EditorToolbar,
  },

  props: {
    editor: {
      type: Object,
      required: false,
    },
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style scoped lang="scss">
:deep([contenteditable="true"]) {
  &:focus-visible {
    outline: none;
  }
}
</style>
