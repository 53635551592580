<script>
import RecentUsersPanel from "./components/RecentUsersPanel.vue";

export default {
  name: "AdminDashboard",
  components: {
    RecentUsersPanel,
  },
  props: {
    recent_users: {
      type: Array,
      required: true,
    },
  },
};
</script>
<template>
  <section class="grid grid-cols-3 gap-2">
    <RecentUsersPanel :recent_users="recent_users" />
  </section>
</template>
